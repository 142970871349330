'use client';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import TextInput, { TextInputProps } from './TextInput';
import { IconSearch, IconX } from '@tabler/icons-react';
import { debounce } from 'lodash-es';
import useLoadTracker from '../../Hooks/useLoadTracker';
import NotificationUtils from '../../Utils/NotificationUtils';

export type SearchInputProps = Omit<TextInputProps, 'type' | 'autoComplete'> & {
	searchTimeoutMs?: number;
	onSearch?: (value: string) => Promise<void>;
};

export default forwardRef<HTMLInputElement, SearchInputProps>(function SearchInput(props: SearchInputProps, ref) {
	const { onSearch, onChange, value } = props;
	const searchTimeoutMs = props.searchTimeoutMs ?? 750;

	const { isLoading, addLoader, removeLoader } = useLoadTracker();

	const executeSearch = useCallback(
		async (query: string) => {
			if (onSearch) {
				const loader = addLoader();
				try {
					await onSearch(query);
				} catch (err) {
					NotificationUtils.showError(err as Error, 'Failed to search');
				} finally {
					removeLoader(loader);
				}
			}
		},
		[addLoader, onSearch, removeLoader],
	);

	const executeSearchDebounced = useMemo(
		() => debounce(executeSearch, searchTimeoutMs),
		[executeSearch, searchTimeoutMs],
	);

	const [searchValue, setSearchValue] = useState<string>(value ?? '');

	useEffect(() => {
		setSearchValue(value ?? '');
	}, [value]);

	const handleClearClicked = () => {
		setSearchValue('');
		executeSearchDebounced.cancel();
		executeSearch('');
	};

	const handleSearchChanged = (value: string) => {
		onChange?.(value);
		if (value) {
			setSearchValue(value);
			executeSearchDebounced(value);
		} else {
			handleClearClicked();
		}
	};

	return (
		<div className={`SearchInput ${props.className ?? ''}`}>
			<TextInput
				{...props}
				ref={ref}
				value={searchValue}
				onChange={handleSearchChanged}
				disabled={isLoading || props.disabled}
				autoComplete="off"
			/>
			{searchValue ? <IconX onClick={handleClearClicked} size={20} /> : <IconSearch size={20} />}
		</div>
	);
});
