import type { Coupon } from '@dr-pam/common-types/database';

export type MinimalCoupon = Pick<Coupon, 'discountType'> & Partial<Pick<Coupon, 'percentage' | 'fixedAmountInCents'>>;

export default class CouponUtils {
	public static getDiscountAmount(priceInCents: number, coupon: MinimalCoupon) {
		if (coupon.discountType === 'PERCENTAGE' && coupon.percentage != null && coupon.percentage !== undefined) {
			return Math.round(priceInCents * coupon.percentage);
		} else if (
			coupon.discountType === 'FIXED_AMOUNT' &&
			coupon.fixedAmountInCents != null &&
			coupon.fixedAmountInCents !== undefined
		) {
			return coupon.fixedAmountInCents;
		} else {
			throw new Error('Unknown coupon discount type');
		}
	}

	public static getDiscountedPriceUsingCoupon(priceInCents: number, coupon: MinimalCoupon) {
		const discount = this.getDiscountAmount(priceInCents, coupon);
		return Math.max(priceInCents - discount, 0);
	}

	public static couponToString(coupon: MinimalCoupon) {
		if (coupon.discountType === 'PERCENTAGE' && coupon.percentage != null && coupon.percentage !== undefined) {
			return `${coupon.percentage * 100}%`;
		} else if (
			coupon.discountType === 'FIXED_AMOUNT' &&
			coupon.fixedAmountInCents != null &&
			coupon.fixedAmountInCents !== undefined
		) {
			return `$${coupon.fixedAmountInCents / 100}`;
		} else {
			throw new Error('Unknown coupon discount type');
		}
	}
}
