import React from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { StripePaymentElementOptions } from '@stripe/stripe-js';
import { forwardRef, useImperativeHandle } from 'react';
import Loading from '../Misc/Loading';
import { StripeElementsRef } from './StripeProvider';

export default forwardRef<StripeElementsRef, { options: StripePaymentElementOptions }>(function StripePaymentElement(
	{ options },
	ref,
) {
	const stripe = useStripe();
	const elements = useElements();

	useImperativeHandle(
		ref,
		() => ({
			stripe,
			elements,
		}),
		[stripe, elements],
	);

	if (!stripe || !elements) {
		return <Loading />;
	}

	return <PaymentElement options={options} />;
});
