import { useMemo } from 'react';
import useAbortRegistry from '../Hooks/useAbortRegistry';
import FetchUtils from '../Utils/FetchUtils';
import {
	GetPaymentIntentRequest,
	PaymentMethod,
	PaymentMethodForUpdate,
	PurchaseSubscriptionRequest,
	PurchaseSubscriptionResponse,
	StripePaymentIntentWithProducts,
	SuccessfulPayment,
} from '../Models/Payment';
import { RegisterAbortFunction } from '../Utils/AbortUtils';
import { Coupon } from '@dr-pam/common-types/database';

export default class PaymentService {
	constructor(private readonly _registerAbort?: RegisterAbortFunction) {}

	public static normalisePaymentAmount(amount: string | number): number {
		amount = amount.toString();
		// ensure that the provided amount can be parsed as a number
		const parsedFloat = parseFloat(amount);
		if (isNaN(parsedFloat)) {
			throw new Error('Could not parse amount as a number');
		}
		// amount must be in cents, round off to the nearest cent
		return Math.round(parsedFloat * 100);
	}

	public createPayment(purchaseSubscriptionRequest: PurchaseSubscriptionRequest) {
		const request = FetchUtils.postJson<PurchaseSubscriptionResponse>(
			'/api/payment/subscribe',
			purchaseSubscriptionRequest,
		);

		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public findPaymentIntent(paymentIntentId: string, clientSecret: string) {
		const request = FetchUtils.postJson<StripePaymentIntentWithProducts, GetPaymentIntentRequest>(
			`/api/payment/intent/find`,
			{
				paymentIntentId,
				clientSecret,
			},
		);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public createSetupIntent() {
		const request = FetchUtils.postJson<{ clientSecret: string }>('/api/payment/intent/setup', {});
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getPaymentMethods() {
		const request = FetchUtils.getJson<PaymentMethod[]>('/api/payment/method');
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public updatePaymentMethod(paymentMethod: PaymentMethodForUpdate) {
		const request = FetchUtils.putJson<PaymentMethod, PaymentMethodForUpdate>(
			`/api/payment/method/${paymentMethod.id}`,
			paymentMethod,
		);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public markPaymentMethodAsPrimary(stripePaymentMethodId: string) {
		const request = FetchUtils.postJson(`/api/payment/method/${stripePaymentMethodId}/primary`, {});
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public deletePaymentMethod(stripePaymentMethodId: string) {
		const request = FetchUtils.deleteJson(`/api/payment/method/${stripePaymentMethodId}`);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public getPayments() {
		const request = FetchUtils.getJson<SuccessfulPayment[]>(`/api/payment`);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}

	public requestCoupon(couponCode: string, productId: string) {
		const request = FetchUtils.getJson<Coupon>(`/api/payment/coupon/${encodeURI(couponCode)}/${productId}`);
		return FetchUtils.abortableRequest(request, this._registerAbort);
	}
}

export function usePaymentService() {
	const registerAbort = useAbortRegistry();

	return useMemo(() => new PaymentService(registerAbort), [registerAbort]);
}
