import React, { forwardRef, ReactNode, useCallback, useImperativeHandle, useRef, useState } from 'react';
import CurrencyUtils from '../../Utils/CurrencyUtils';
import { type Coupon } from '@dr-pam/common-types/database';
import Button from '../Form/Button';
import TextInput from '../Form/TextInput';
import useLoadTracker from '../../Hooks/useLoadTracker';
import Loading from '../Misc/Loading';
import CouponUtils from '../../Utils/CouponUtils';
import FormControl from '../Form/FormControl';

export type CartItem = {
	description: ReactNode;
	subDescription?: ReactNode;
	priceInCents: number;
	className?: string;
};

export type CartProps = {
	className?: string;
	items: CartItem[];
	coupon?: Coupon;
	onCouponRequested?: (couponCode: string) => Promise<void>;
	onCouponChanged?: (couponCode: string) => void;
	onCouponRemoved?: () => void;
	inputError?: string;
};

export type CartRef = {
	hasUnappliedCoupon: () => boolean;
	requestCurrentCoupon: () => Promise<void>;
};

export default forwardRef<CartRef, CartProps>(function Cart(props: CartProps, ref) {
	const { className, items, coupon, onCouponRequested, onCouponRemoved, inputError } = props;

	const elRef = useRef<HTMLInputElement>(null);

	const [showCodeInput, setShowCodeInput] = useState(false);
	const [couponCode, setCouponCode] = useState('');

	const { addLoader, removeLoader, isLoading } = useLoadTracker();

	const filteredItems = items.filter((item) => item.description);

	const handleCouponCodeChange = (value: string) => {
		setCouponCode(value);
		props.onCouponChanged?.(value);
		if (!value.length) {
			setShowCodeInput(false);
			onCouponRemoved?.();
		}
	};

	const handleAddDiscountClicked = () => {
		setShowCodeInput(true);
		setTimeout(() => {
			elRef.current?.focus();
		}, 100);
	};

	const handleRequestCouponClicked = useCallback(async () => {
		if (!couponCode) {
			return;
		}
		const loader = addLoader();
		try {
			await onCouponRequested?.(couponCode);
		} finally {
			removeLoader(loader);
			setCouponCode('');
			setShowCodeInput(false);
		}
	}, [addLoader, couponCode, onCouponRequested, removeLoader]);

	useImperativeHandle(
		ref,
		() => ({
			hasUnappliedCoupon: () => couponCode.length > 0 && couponCode != coupon?.code,
			requestCurrentCoupon: handleRequestCouponClicked,
		}),
		[coupon?.code, couponCode, handleRequestCouponClicked],
	);

	const subtotal = filteredItems.reduce((total, item) => total + item.priceInCents, 0);
	const discount = coupon ? CouponUtils.getDiscountAmount(subtotal, coupon) : 0;
	const total = coupon ? CouponUtils.getDiscountedPriceUsingCoupon(subtotal, coupon) : subtotal;

	return (
		<div className={`Cart ${className ?? ''}`}>
			{filteredItems.map((item, i) => (
				<div className={`line ${item.className ?? ''}`} key={i}>
					<div className="description">
						{item.description}
						<div className="sub-description">{item.subDescription}</div>
					</div>
					<div className="price">{CurrencyUtils.format(item.priceInCents)}</div>
				</div>
			))}
			{(onCouponRequested || coupon) && (
				<>
					<div className="line subtotal">
						<div className="description">Subtotal</div>
						<div className="price">{CurrencyUtils.format(subtotal)}</div>
					</div>
					<div className={`line discount ${showCodeInput ? 'input-visible' : ''}`}>
						<div>Discount</div>
						{showCodeInput ? (
							<div className="discount-input">
								<FormControl name="coupon-code" error={inputError}>
									<TextInput
										placeholder="Enter your code"
										value={couponCode}
										onChange={handleCouponCodeChange}
										disabled={isLoading}
										ref={elRef}
									/>
								</FormControl>
								<Button
									disabled={isLoading}
									onClick={() => handleRequestCouponClicked()}
									className={isLoading ? 'loading' : ''}
								>
									{isLoading ? <Loading size="small" /> : 'Apply'}
								</Button>
							</div>
						) : coupon ? (
							<>
								<div className="price">
									{onCouponRemoved && (
										<Button className="subtle small" onClick={onCouponRemoved}>
											Remove
										</Button>
									)}
									{CurrencyUtils.format(Math.abs(discount))}
								</div>
							</>
						) : (
							<Button className="subtle small" onClick={handleAddDiscountClicked}>
								Add discount code
							</Button>
						)}
					</div>
				</>
			)}
			<div className="line total">
				<div className="description">Total</div>
				<div className="price">{CurrencyUtils.format(total)}</div>
			</div>
		</div>
	);
});
