export default class SessionStorageUtil {
	public static clear(): void {
		return this._sessionStorage?.clear();
	}

	public static hasItem(key: string): boolean {
		return this._sessionStorage?.getItem(key) !== null;
	}

	public static getItem(key: string): string | null {
		return this._sessionStorage?.getItem(key) ?? null;
	}

	public static getItemAndParse<T>(key: string): T | null {
		const item = this.getItem(key);
		return item ? JSON.parse(item) : null;
	}

	public static key(index: number): string | null {
		return this._sessionStorage?.key(index) ?? null;
	}

	public static removeItem(key: string): void {
		return this._sessionStorage?.removeItem(key);
	}

	public static setItem(key: string, value: string): void {
		return this._sessionStorage?.setItem(key, value);
	}

	private static _sessionStorage: Storage | null =
		typeof window !== 'undefined' && typeof window.sessionStorage !== 'undefined' ? window.sessionStorage : null;
}
