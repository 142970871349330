'use client';
import React, { forwardRef, useEffect, useState } from 'react';
import { useFormContext } from './Form';
import { useFormControlContext } from './FormControl';
import { IconCheck } from '@tabler/icons-react';

export type CheckboxProps = {
	className?: string;
	disabled?: boolean;
	value?: boolean;
	size?: 'small' | 'medium' | 'large';
	onChange?: (value: boolean) => void;
};

export default forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(props, ref) {
	const { className, value, onChange } = props;
	const size = props.size ?? 'medium';

	const [inputValue, setInputValue] = useState(value ?? false);

	useEffect(() => {
		if (value !== undefined) {
			setInputValue(value);
		}
	}, [value]);

	const formContext = useFormContext();
	const formControl = useFormControlContext();

	const disabled = props.disabled || formControl?.disabled || formContext?.disabled;
	const formInputProps =
		formContext && formControl
			? formContext.form.getInputProps(formControl.name, { type: 'checkbox' })
			: {
					checked: inputValue,
					onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
						const val = e.target.checked;
						setInputValue(val);
						onChange?.(val);
					},
			  };

	return (
		<div className={`Checkbox ${className ?? ''} ${formInputProps.checked ? 'checked' : ''}`}>
			<input
				ref={ref}
				type="checkbox"
				id={formControl?.fieldId}
				className={size}
				disabled={disabled}
				{...formInputProps}
			/>
			<IconCheck />
		</div>
	);
});
