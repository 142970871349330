export default class CurrencyUtils {
	public static getDuration(days: number | null, isAutoRenew = false) {
		if (days === null) {
			return '';
		}
		if (!isAutoRenew) {
			return `for ${days} days access`;
		}
		if (days === 7) {
			return 'week';
		} else if (days >= 28 && days <= 31) {
			return 'month';
		} else if (days === 365 || days === 366) {
			return 'year';
		} else {
			return `/ ${days} days`;
		}
	}

	public static format(cents: number, currency = 'AUD', locale = 'en-AU') {
		return new Intl.NumberFormat(locale, {
			style: 'currency',
			currency,
		}).format(cents / 100);
	}
}
