'use client';

import { useEffect, useMemo, useRef } from 'react';
import useLoadTracker from '../Hooks/useLoadTracker';
import FetchUtils from '../Utils/FetchUtils';
import { Loader } from '@googlemaps/js-api-loader';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from '@dr-pam/common-types/json';

export type GeoIpDetails = {
	ip: string;
	city: string;
	region: string;
	region_code: string;
	country: string;
	country_name: string;
	country_code: string;
	postal: string;
	latitude: number;
	longitude: number;
	country_calling_code: string;
	currency: string;
	currency_name: string;
	languages: string;
};

export default class GoogleMapsService {
	private _geocoder: google.maps.Geocoder | null = null;
	private _loadPromise: Promise<typeof google> | null = null;

	public getApproximateLocation() {
		const request = FetchUtils.getJson<GeoIpDetails>('https://ipapi.co/json');
		return FetchUtils.abortableRequest(request);
	}

	public async waitForGoogleMapsApi() {
		if (!this._loadPromise) {
			const loader = new Loader({
				apiKey: 'AIzaSyBm2IDMzMkp2mjs4_ygVnNhmwR9eICH1uM',
			});
			this._loadPromise = loader.loadPromise();
		}

		return this._loadPromise;
	}

	public async bindPlaceAutocompleteToInput(
		inputField: HTMLInputElement,
		opts?: google.maps.places.AutocompleteOptions,
	): Promise<google.maps.places.Autocomplete> {
		await this.waitForGoogleMapsApi();
		const places = (await google.maps.importLibrary('places')) as google.maps.PlacesLibrary;
		return new places.Autocomplete(inputField, opts);
	}

	public convertPlaceToAddress(place: Place): SimpleAddress {
		const addressEl = document.createElement('div');
		addressEl.innerHTML = place.adr_address ?? '';

		const address: SimpleAddress = {
			line1: addressEl.querySelector('.street-address')?.innerHTML ?? '',
			line2: addressEl.querySelector('.extended-address')?.innerHTML,
			city: addressEl.querySelector('.locality')?.innerHTML ?? '',
			state: addressEl.querySelector('.region')?.innerHTML ?? '',
			postalCode: addressEl.querySelector('.postal-code')?.innerHTML ?? '',
			country: addressEl.querySelector('.country-name')?.innerHTML ?? '',
		};

		return address;
	}

	public async geocode(request: google.maps.GeocoderRequest) {
		await this.waitForGoogleMapsApi();
		if (!this._geocoder) {
			this._geocoder = new google.maps.Geocoder();
		}

		return this._geocoder.geocode(request);
	}

	public getCurrentPosition() {
		return new Promise<GeolocationPosition>((resolve, reject) => {
			navigator.geolocation.getCurrentPosition(resolve, reject);
		});
	}

	public async sortByDistanceFromPoint<T>(
		point: google.maps.LatLngLiteral,
		items: T[],
		accessor: (item: T) => google.maps.LatLngLiteral,
	) {
		await this.waitForGoogleMapsApi();
		const { spherical } = (await google.maps.importLibrary('geometry')) as google.maps.GeometryLibrary;

		items.sort((a, b) => {
			const aDistance = spherical.computeDistanceBetween(accessor(a), point);
			const bDistance = spherical.computeDistanceBetween(accessor(b), point);
			return aDistance - bDistance;
		});
		return items;
	}
}

export function useGoogleMapsService(): GoogleMapsService {
	const googleMapsService = useMemo(() => new GoogleMapsService(), []);
	return googleMapsService;
}

export type Place = google.maps.places.PlaceResult;

export type OnPlaceChangedHandler = (place: Place) => void;

export type AddressWithDetails = {
	name: string;
	address: string;
	phone: string;
	email: string;
	website: string;
	location?: {
		lat: number;
		lng: number;
	};
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type SimpleAddress = PrismaJson.SimpleAddress & {};

export function simpleAddressToString(address: SimpleAddress, separator = `\n`) {
	const lines = [
		address.line1.trim(),
		address.line2?.trim(),
		[address.city.trim(), address.state.trim(), address.postalCode.trim()].join(' '),
		address.country.trim(),
	];
	return lines.filter((line) => !!line).join(separator);
}

export function useGoogleMapsAutocomplete(
	inputField: HTMLInputElement | null,
	onPlaceChanged: OnPlaceChangedHandler,
	opts?: google.maps.places.AutocompleteOptions,
) {
	const { addLoader, removeLoader, isLoading } = useLoadTracker();
	const googleMapsService = useGoogleMapsService();

	const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
	const listenerRef = useRef<google.maps.MapsEventListener | null>(null);

	useEffect(() => {
		let loader: string | null = null;

		if (inputField && googleMapsService) {
			loader = addLoader();
			googleMapsService
				.bindPlaceAutocompleteToInput(inputField, {
					fields: ['address_component', 'adr_address', 'formatted_address', 'name', 'place_id', 'geometry'],
					...opts,
				})
				.then((ac) => {
					autocompleteRef.current = ac;
					listenerRef.current = autocompleteRef.current.addListener('place_changed', () => {
						if (autocompleteRef.current) {
							const place = autocompleteRef.current.getPlace();
							onPlaceChanged(place);
						}
					});
				})
				.finally(() => {
					if (loader) {
						removeLoader(loader);
					}
				});
		}

		return () => {
			listenerRef.current?.remove();
			autocompleteRef.current?.unbindAll();
			if (loader) {
				removeLoader(loader);
			}
		};
	}, [addLoader, inputField, googleMapsService, removeLoader, opts, onPlaceChanged]);

	return {
		isLoading,
		autocompleteRef,
		listenerRef,
	};
}
