import React, { forwardRef } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, StripePaymentElementOptions } from '@stripe/stripe-js';
import { StripeElementsRef, createStripeElementsOptions, useStripeProvider } from './StripeProvider';
import StripePaymentElement from './StripePaymentElement';

export type StripePaymentFormProps = {
	className?: string;
	clientSecret: string;
	elementOptions?: StripeElementsOptions;
	paymentElementOptions?: StripePaymentElementOptions;
	color: string;
};

export default forwardRef<StripeElementsRef, StripePaymentFormProps>(function StripePaymentForm(props, ref) {
	const { className, clientSecret, color } = props;

	const stripeContext = useStripeProvider();

	const elementOptions = createStripeElementsOptions(color, clientSecret);

	const paymentElementOptions: StripePaymentElementOptions = props.paymentElementOptions ?? {};
	paymentElementOptions.layout = 'tabs';
	paymentElementOptions.fields = {
		billingDetails: {
			name: 'never',
			email: 'never',
			//phone: 'never',
			address: 'never',
		},
	};

	return (
		<div className={`StripePaymentForm ${className ?? ''}`}>
			<Elements stripe={stripeContext.promise} options={elementOptions}>
				<StripePaymentElement options={paymentElementOptions} ref={ref} />
			</Elements>
		</div>
	);
});
