import React, { ReactNode, useRef, useState } from 'react';
import type { ProductSubscriptionType } from '@dr-pam/common-types/database';
import CurrencyUtils from '../../Utils/CurrencyUtils';
import { IconCircleCheck } from '@tabler/icons-react';
import Button from '../Form/Button';

export type SubscriptionComparisonProps = {
	className?: string;
	items: ProductSubscriptionType[];
	children?: ReactNode;
	title?: ReactNode;
	selectedItem?: ProductSubscriptionType;
	onItemSelected?: (item: ProductSubscriptionType) => void;
};

export default function SubscriptionComparison(props: SubscriptionComparisonProps) {
	const { className, items, children, title, onItemSelected } = props;

	const elRef = useRef<HTMLDivElement>(null);

	const [selectedItem, setSelectedItem] = useState<ProductSubscriptionType | undefined>(props.selectedItem);

	if (items.length === 0) {
		throw new Error('Must have at least 1 item.');
	}

	const handleItemSelected = (item: ProductSubscriptionType) => {
		setSelectedItem(item);
		onItemSelected?.(item);
		setTimeout(() => {
			const scrollEl = elRef.current?.closest('.body');
			if (scrollEl) {
				scrollEl.scrollTo({ top: scrollEl.scrollHeight, behavior: 'smooth' });
			}
		}, 50);
	};

	const sortedItems = items.sort((a, b) => (a.durationInDays ?? 0) - (b.durationInDays ?? 0));
	const referenceItem = sortedItems[0];
	const referenceCostPerDay = getCostPerDay(referenceItem);

	return (
		<div className={`SubscriptionComparison ${className ?? ''}`} ref={elRef}>
			{title && <h3>{title}</h3>}
			<p>Select a plan that works best for you. All prices are in AUD.</p>
			<table>
				<thead>
					<tr>
						<th>&nbsp;</th>
						<th>$/day</th>
						<th>Renewal</th>
						<th>Amount</th>
						<th>&nbsp;</th>
					</tr>
				</thead>
				<tbody>
					{items.map((item) => {
						const costPerDay = getCostPerDay(item);
						const discount = Math.round(((referenceCostPerDay - costPerDay) / referenceCostPerDay) * 100);
						const discountRounded = Math.round(discount / 5) * 5;
						return (
							<tr
								key={item.id}
								className={selectedItem === item ? 'selected' : undefined}
								onClick={() => handleItemSelected(item)}
							>
								<td>
									<div>
										<span>{item.name}</span>
										{discountRounded > 0 ? (
											<span className="discount">Save {discountRounded}%</span>
										) : null}
									</div>
								</td>
								<td>{CurrencyUtils.format(costPerDay)}</td>
								<td>{item.durationInDays} days</td>
								<td>{CurrencyUtils.format(item.priceInCents)}</td>
								<td>
									<div>
										{selectedItem === item ? (
											<IconCircleCheck />
										) : (
											<Button className="secondary">Select</Button>
										)}
									</div>
								</td>
							</tr>
						);
					})}
				</tbody>
			</table>
			<p className="footnote">Plans renew automatically. You can cancel anytime.</p>
			{children}
		</div>
	);
}

function getCostPerDay(item: ProductSubscriptionType) {
	return (item.priceInCents ?? 0) / (item.durationInDays ?? 1);
}
