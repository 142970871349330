'use client';
import React, { ReactNode, forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { UseFormReturnType, useForm, zodResolver } from '@mantine/form';
import FormControl from '../Form/FormControl';
import TextInput from '../Form/TextInput';
import Button from '../Form/Button';
import Form from '../Form/Form';
import useLoadTracker from '../../Hooks/useLoadTracker';
import { ZodSchema, z } from 'zod';
import { User } from '@dr-pam/common-types/database';
import {
	OnPlaceChangedHandler,
	SimpleAddress,
	simpleAddressToString,
	useGoogleMapsAutocomplete,
	useGoogleMapsService,
} from '../../Services/GoogleMapsService';
import Label from '../Form/Label';
import SearchInput from '../Form/SearchInput';

const validationSchema = z.object({
	email: z.string().email('Invalid email').nonempty('Email is required'),
	fullName: z.string().nonempty('Full Name is required'),
});

export type SignupFormProps = {
	className?: string;
	onSubmit?: (values: SignupFormType) => Promise<void>;
	submitButtonText?: ReactNode;
	children?: ReactNode;
	additionalButtons?: ReactNode;
	showLabels?: boolean;
	includeBillingAddress?: boolean;
	prefillUser?: Partial<User>;
};

export default forwardRef<UseFormReturnType<SignupFormType>, SignupFormProps>(function SignupForm(
	props: SignupFormProps,
	ref,
) {
	const { className, onSubmit, children, additionalButtons, showLabels, includeBillingAddress, prefillUser } = props;
	const submitButtonText = props.submitButtonText ?? 'Create account';

	const googleMapsService = useGoogleMapsService();

	const [searchValue, setSearchValue] = useState<string>('');
	const [searchEl, setSearchEl] = useState<HTMLInputElement | null>(null);

	let patchedValidationSchema: ZodSchema = validationSchema;
	if (!prefillUser) {
		patchedValidationSchema = validationSchema
			.and(
				z.object({
					password: z
						.string()
						.nonempty('Password is required')
						.min(12, 'Password must be at least 12 characters'),
					confirmPassword: z.string().nonempty('Confirm password is required'),
				}),
			)
			.refine((data) => data.password === data.confirmPassword, {
				message: 'Passwords do not match',
				path: ['confirmPassword'],
			});
	}
	if (includeBillingAddress) {
		patchedValidationSchema = patchedValidationSchema
			.and(
				z.object({
					_billingAddress: z.string().min(1, 'Billing address is required'),
					billingCountryCode: z.string().nullable(),
				}),
			)
			.refine(
				(data) => {
					return data.billingCountryCode && data._billingAddress;
				},
				{
					message: 'Billing address is required',
					path: ['_billingAddress'],
				},
			);
	}
	const { isLoading, addLoader, removeLoader } = useLoadTracker();
	const [error, setError] = useState<ReactNode | null>(null);

	const form = useForm<SignupFormType>({
		initialValues: {
			email: prefillUser?.email ?? '',
			password: '',
			confirmPassword: '',
			fullName: prefillUser?.fullName ?? '',
			billingAddress: (prefillUser?.billingAddress as undefined | null | SimpleAddress) || null,
			billingCountryCode: prefillUser?.billingCountryCode ?? null,
			_billingAddress: prefillUser?.billingAddress
				? simpleAddressToString(prefillUser.billingAddress as SimpleAddress, ', ')
				: '',
		},
		validate: zodResolver(patchedValidationSchema),
	});

	useImperativeHandle(ref, () => form, [form]);

	const onPlaceChanged: OnPlaceChangedHandler = useCallback(
		(place) => {
			const countryCode = place.address_components?.find((c) => c.types.includes('country'))?.short_name;
			const address = googleMapsService.convertPlaceToAddress(place);

			setSearchValue('');
			form.setFieldValue('billingCountryCode', countryCode ?? 'AU');
			form.setFieldValue('billingAddress', address);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[googleMapsService],
	);

	const handleChangeAddressClicked = () => {
		form.setFieldValue('_billingAddress', '');
		form.setFieldValue('billingCountryCode', null);
		form.setFieldValue('billingAddress', null);
	};

	const { isLoading: isSearchingAddress } = useGoogleMapsAutocomplete(searchEl, onPlaceChanged);

	const onFormSubmit = async (values: SignupFormType) => {
		if (!onSubmit) {
			return;
		}

		const loader = addLoader();
		try {
			setError(null);
			await onSubmit(values);
		} catch (err) {
			if (err instanceof Error) {
				setError(err.message);
			} else if (err) {
				setError(`${err}`);
			} else {
				setError('Unknown error');
			}
		} finally {
			removeLoader(loader);
		}
	};

	const isAnyLoading = isLoading || isSearchingAddress;

	return (
		<div className={`SignupForm ${className ?? ''} ${prefillUser ? 'user-prefilled' : ''}`}>
			<Form
				form={form}
				onSubmit={onFormSubmit}
				disabled={isLoading}
				onValidationFailure={(errors) => console.log(errors)}
			>
				{!prefillUser && (
					<>
						<FormControl name="fullName" label={showLabels ? 'Full name' : ''}>
							<TextInput placeholder="Full name" autoComplete="name" />
						</FormControl>
						<FormControl name="email" label={showLabels ? 'Email address' : ''}>
							<TextInput type="email" placeholder="email address" autoComplete="email" />
						</FormControl>
						<FormControl name="password" label={showLabels ? 'Password' : ''}>
							<TextInput type="password" placeholder="Password" />
						</FormControl>
						<FormControl name="confirmPassword" label={showLabels ? 'Confirm password' : ''}>
							<TextInput type="password" placeholder="Confirm password" />
						</FormControl>
					</>
				)}
				{includeBillingAddress && (
					<>
						{form.values.billingAddress ? (
							<div className="billing-address">
								<Label>Billing address</Label>
								<div
									className="address"
									dangerouslySetInnerHTML={{
										__html: simpleAddressToString(form.values.billingAddress, '<br/>'),
									}}
								/>

								<Button className="subtle" onClick={handleChangeAddressClicked}>
									Change address
								</Button>
							</div>
						) : (
							<FormControl label="Billing address" name={'_billingAddress'}>
								<SearchInput
									className="address-search"
									onChange={setSearchValue}
									value={searchValue}
									ref={setSearchEl}
									disabled={isLoading}
									placeholder="Search for address"
								/>
							</FormControl>
						)}
					</>
				)}
				{error && <div className="error">{error}</div>}
				<div className="buttons">
					{additionalButtons}
					<Button type="submit" isLoading={isAnyLoading}>
						{submitButtonText}
					</Button>
				</div>
				{children}
			</Form>
		</div>
	);
});

export type SignupFormType = Pick<User, 'email' | 'fullName'> & {
	_billingAddress: string;
	billingAddress: SimpleAddress | null;
	billingCountryCode: string | null;
	password: string;
	confirmPassword: string;
};
